import { Print } from '../Libraries/Print';
const jsPrintButtons = document.querySelectorAll(".js-print");
const logoUrl = '/dist/assets/images/logo-sm.png';

for (const pBtn of jsPrintButtons) {
    const printEl = pBtn.dataset.print;
    const printCss = pBtn.dataset.css;
    const pageSize = pBtn.dataset.pageSize;
    const logo = new Image(150, 80); 
    logo.src = logoUrl;
    logo.classList.add("print-logo");
    const printElement = document.querySelector(printEl);



    pBtn.addEventListener("click", () => {
        printElement.prepend(logo); 
        new Print(printEl, {
            css: printCss,
            setPageSize: pageSize
        });
    });
}